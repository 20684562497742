<template>
  <div class="flexdc flex1" style="height: 100%">
    <div
      class="operationControl flexdc operationControlTest"
      style="align-items: flex-start"
    >
      <div class="searchbox">
        <div>
          <div
            title="试卷名称"
            class="searchboxItem"
            style="display: flex; align-items: center"
          >
            <span
              class="itemLabel"
              style="
                min-width: 5rem;
                text-align: right;
                font-size: 14px;
                padding: 0 0.5rem;
              "
              >试卷名称:</span
            >
            <el-input
              v-model="searchName"
              type="text"
              size="small"
              placeholder="请输入试卷名称"
              clearable
            />
          </div>
          <div class="df">
            <el-button
              class="bgc-bv"
              round
              @click="getData()"
              size="small"
              >查询</el-button
            >
          </div>
        </div>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="addTestPaper()"
            >新增试卷</el-button
          >
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          :header-cell-style="tableHeader"
        >
          <el-table-column
            fixed
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
          />
          <el-table-column
            label="试卷名称"
            align="left"
            show-overflow-tooltip
            prop="paperName"
            min-width="200"
            fixed
          />
          <el-table-column
            label="创建人"
            align="left"
            prop="createName"
            show-overflow-tooltip
          />
          <el-table-column
            label="创建日期"
            align="left"
            prop="createTime"
            show-overflow-tooltip
          />
          <el-table-column
            label="被使用次数"
            align="left"
            prop="useNum"
            show-overflow-tooltip
          />

          <el-table-column label="操作" align="center" fixed="right">
            <div slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="seePaperEdit(scope.row.paperId, scope.row.paperOrgin)"
                >查看</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="deletePaper(scope.row.paperId)"
                >删除</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />

  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
import { console } from "@/utils/helper";
export default {
  name: "testPaperList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      activeName: "first",
      searchName: "",
    };
  },
  created() {},
  computed: {},
  methods: {
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperName: this.searchName || "",
      };
      this.doFetch({
        url: "/evaluate/paper/pageList",
        params,
        pageNum,
      });
    },
    seePaperEdit(paperId, paperOrgin) {
      this.$router.push({
        path: "/web/appraisalInstitution/testPaperEdit",
        query: {
          paperId,
          paperOrgin,
        },
      });
    },
    deletePaper(paperId) {
      this.doDel({
        title: "删除",
        url: "/evaluate/paper/delete",
        msg: "你确定要删除该试卷吗？",
        ps: {
          type: "post",
          data: { paperId: paperId },
        },
      });
    },
    // 新增 编辑
    addTestPaper() {
      this.$router.push({
        path: "/web/appraisalInstitution/addTestPaper",
      });
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.dialogVisible == false
            ? 90 + 0.675 * 16 + 1
            : 120 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  // beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.operationControlTest {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
.textRed {
  color: red;
}
</style>
