<template>
  <div class="pageContol testpaperList listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">鉴定机构管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">试卷列表</a>
        </span>
      </div>
      <div class="framePage-body">
          <el-tabs v-model="activeName" :before-leave="beforeTabLeave">
            <el-tab-pane label="我的试卷" name="first">
               <MyPaper ref="first" />
            </el-tab-pane>
            <!-- <el-tab-pane label="我的题库" name="second">
               <myQuestionBank ref="second" />
            </el-tab-pane> -->
          </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
import MyPaper from "@/views/appraisalInstitution/testPaperList/myPaper.vue";
// import myQuestionBank from "@/views/resourse/myPaperQuestionList/myQuestionBank.vue";
export default {
  name: "testPaperList",
  components: {
    MyPaper,
    // myQuestionBank,
  },
  mixins: [List],
  data() {
    return {
      activeName: "first"
    };
  },
   methods: {
    init() {
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
      } else {
        this.activeName = "first";
      }
       this.$refs[tab.name].getData(-1);
    },
    tabChange(tab) {
      this.$refs[tab.name].queryStr = "";
      this.$refs[tab.name].getData(-1);
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    }
  },
};
</script>
<style lang="less" >
.testpaperList {
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.searchbox {
  padding: 0;
}
.operationControlTest {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
